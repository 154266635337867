import type { MetaFunction, LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Link, useLoaderData, useRouteError, isRouteErrorResponse } from "@remix-run/react";
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import {getAllTrendingRepos} from '../server/trending.server';
import {RepoResult} from '../components/repo-list-item';
import {RemixRepoListItem} from '../components/remix-repo-list-item';
import {apiCacheControlHeader} from '../server/utils.server';

export const listTypeMap = {
  today: {
    timeText: 'today',
    headerText: 'Trending today',
    titleText: 'Top trending open source projects today',
    ghLink: 'https://github.com/trending?since=daily'
  },
  weekly: {
    timeText: 'this week',
    headerText: 'Trending this week',
    titleText: 'Top trending open source projects this week',
    ghLink: 'https://github.com/trending?since=weekly'
  },
}

export const meta: MetaFunction = ({ data, params }: { data: any, params: any }) => {
  const listTypeData = listTypeMap[params.listType as string];
  const title = listTypeData ? listTypeData.titleText : 'Not found';
  const description = listTypeData ?  `Top trending open source software repositories for ${listTypeData.timeText}. These growing projects are receiving exceptional interest and development from users.`: 'Error loading trending';
  const canonicalURL = `${__BASE_URL__}/trending/${params.listType}`;
  const jsonLD = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": title,
    "description": description,
    "url": canonicalURL
  };

  return [
    { title },
    { name: "description", content: description },
    { tagName: "link", rel: "canonical", href: canonicalURL },
    { "script:ld+json": jsonLD },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:type", content: "website" },
    { property: "og:url", content: canonicalURL },
    { property: "og:image", content: __OG_IMAGE__ }
  ];
};

export async function loader({params}: LoaderFunctionArgs) {
  if (!listTypeMap.hasOwnProperty(params.listType as string)) {
    throw new Response(null, {
      status: 404,
      statusText: "Not Found",
    });
  }

  const repoResultsAll = getAllTrendingRepos();
  const repoResults = repoResultsAll.filter(r => r.list_type === params.listType);

  return json({
    repoResults, 
    listTypeData: listTypeMap[params.listType as string]
  }, { headers: apiCacheControlHeader });
};

export default function Index() {
  const loaderData: any = useLoaderData();

  return (
    <>
      <div className="background-gray px-2 py-2 border border-gray-100 rounded-md">
        <div className="flex items-center py-1">
          <span className="text-xl font-bold mr-1 ">
            {loaderData.listTypeData.headerText}
          </span>
          <a href={loaderData.listTypeData.ghLink} target="_blank" className="ml-1 tooltip inline-flex items-center" data-tip="View on GitHub" aria-label="View on GitHub">
            <ArrowTopRightOnSquareIcon className="inline-block size-5 text-gray-400" />
          </a>
        </div>
        <p>
          View top trending open source software repositories for {loaderData.listTypeData.timeText} below. These growing projects are receiving exceptional interest and development from users.
        </p>
        <p className="mt-1">
          Click on a specific project to view its alternative or complementary packages. Make comparisons and find the best package for your app.
        </p>
      </div>

      <ul role="list" className="divide-y divide-gray-100" >
        {loaderData.repoResults.map((repoResult: RepoResult, i: number) => (
          <RemixRepoListItem key={i} repoResult={repoResult} />
        ))}
      </ul>

    </>
  );
}


export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
      <div>
        <h1 className="text-xl font-bold mb-2">
          Not found
        </h1>
        <p>That trending time frame was not found.</p>
      </div>
      )
    }
    else {
      return (
        <div>
          <h1>
            {error.status} {error.statusText}
          </h1>
          <p>{error.data}</p>
        </div>
      );
    }
  } else if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }
}
